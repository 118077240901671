// Dependencies
import Page from '../src/components/page/Page';
import React from 'react';
import Router from 'next/router';
import { observer } from 'mobx-react';
import useStores from '../src/helpers/useStores';
import { useForm } from 'react-hook-form';
import { handleErrors } from '@anephenix/ui';

// Styling
import '../styles/index.scss';

const Login = observer(() => {
	const { handleSubmit, register, errors, setError } = useForm();
	const { sessionStore } = useStores();

	const onSubmit = async (values) => {
		try {
			await sessionStore.create(values);
			Router.push({ pathname: '/dashboard' });
			return;
		} catch (err) {
			handleErrors(setError, err);
		}
	};

	return (
		<Page>
			<div id="login-page">
				<form onSubmit={handleSubmit(onSubmit)}>
					<h1>Login</h1>
					<div className="input-group">
						<label htmlFor="name">Username / Email</label>
						<input
							name="identifier"
							ref={register({})}
							type="text"
							placeholder="Username / Email"
						/>
						{errors?.identifier?.message}
					</div>
					<div className="input-group">
						<label htmlFor="name">Password</label>
						<input
							name="password"
							ref={register({})}
							type="password"
							placeholder="Password"
						/>
						{errors?.password?.message}
					</div>
					<button
						className="button theme-default green-one"
						type="submit"
					>
						Login
					</button>
				</form>
			</div>
		</Page>
	);
});

export default Login;
